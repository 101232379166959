import React, { ReactElement, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Link, useNavigate } from "react-router-dom";

/**
 * Context
 */
import { PermissionContext } from "../_contexts/PermissionContext";
/**
 * List
 */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CollapsibleList from './CollapsibleList';

/**
 * Icon
 */
import CropFreeIcon from '@mui/icons-material/CropFree';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Logo
import logo from '../Assets/Images/Logo.png';
// import ApartmentIcon from '@mui/icons-material/Apartment';

// import * as DefaultNotification from "../_utils/DefaultNotification";
// import DefaultEcho from '../_utils/DefaultEcho';
import { checkNotification } from '../_utils/Helper';
import { Theme, useMediaQuery } from '@mui/material';
import { generateStyle } from '../_utils/DefaultStyles';
import { Box } from '@mui/material';

interface Links extends Array<LinksItem | false> { }

interface LinksItem {
  url: string;
  type?: string;
  label: string;
  logo: ReactElement | null;
  children?: Links | false;
  exact?: boolean;
}

interface Props {
  title: string;
  container: any;
  children: any;
  withoutAppBar?: boolean
  isGrayBackground?: boolean
  withoutMainPadding?: boolean
}

function generateLinks(links: Links) {
  return links.map((data, index) => {
    if (!data) {
      return null;
    }
    if (data.type === 'parent' && data.children) {
      const childrenLength = data.children.filter(item => item).length;
      if (childrenLength) {
        return (
          <CollapsibleList
            label={data.label}
            logo={data.logo}
            lists={data.children}
            key={index}
          />
        )
      } else {
        return null;
      }
    } else {
      // console.log(data);
      return (
        <Link to={data.url} style={{ textDecoration: 'none', color: 'black' }} key={data.label}>
          <ListItem>
            {data.logo &&
              <ListItemIcon>
                {data.logo}
              </ListItemIcon>
            }
            <ListItemText primary={data.label} />
          </ListItem>
        </Link>
      )
    }
  })
}


const drawerWidth = 270;

const useStyles = generateStyle((theme: Theme) => ({
  root: {
    display: 'flex',
    '& .MuiListItemIcon-root': {
      minWidth: '45px'
    }
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: "rgb(0, 86, 168)",
    boxShadow: '0px 3px 15px #0000001A',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menubarHeaderContainer: {
    height: '64px',
    // backgroundColor: '#009be5',
    // color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #eee'
  },
  logo: {
    height: '50px'
  },
  grayBackground: {
    backgroundColor: '#EEEEEE',
  },
}), "MenuBar");

function ResponsiveDrawer({ container, withoutAppBar, title, children, isGrayBackground, withoutMainPadding }: Props) {
  const permissions = useContext(PermissionContext);
  const navigate = useNavigate();
  const { Root, classes } = useStyles();
  const theme: Theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [desktopOpen, setDesktopOpen] = React.useState(true)
  const [isUnread, setIsUnread] = React.useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const styles = (theme: Theme) => ({
    toolbar: {
      ...theme.mixins.toolbar,
      display: withoutAppBar ? 'none' : 'block',
    },
  })

  const connectEcho = () => {

  }

  useEffect(() => {
    if (localStorage.getItem("jwt_token") !== null) {
      connectEcho()
      checkNotification()
        .then(res => {
          setIsUnread(res as boolean)
        })
    }
  }, [])

  const url_links: Links = [
    { url: '/', label: 'Dashboard', logo: <DashboardIcon />, exact: true },
    {
      url: '#', type: 'parent', label: 'Operation', logo: <BuildIcon />, children: [
        permissions['lead.manage'] ? { url: '/lead', label: 'Leads', logo: null, exact: true } : false,
        permissions['cs-chat.manage'] ? { url: '/cs-chats', label: 'CS Chats', logo: null, exact: true } : false,
        permissions['lead.see-unassigned'] ? { url: '/lead-unassigned', label: 'Leads Unassigned', logo: null, exact: true } : false,
        permissions['activity.manage'] ? { url: '/activity', label: 'Showing', logo: null, exact: true } : false,
        permissions['closing.view'] ? { url: '/closing', label: 'Closing', logo: null, exact: true } : false,
        permissions['closing-loan.manage-outstanding'] ? { url: '/list-pinjaman', label: 'List Pinjaman', logo: null, exact: true } : false,
        permissions['bank-interest.manage'] ? { url: '/bank-interest', label: 'Bank Interest', logo: null, exact: true } : false,
        permissions['reimbursement.list'] ? { url: '/reimbursement', label: 'Reimbursement', logo: null, exact: true } : false,
        permissions['lead-pause-request.list'] ? { url: '/lead-pause-request', label: 'Lead Pause Request', logo: null, exact: true } : false,
      ]
    },
    {
      url: '#', type: 'parent', label: 'Report', logo: <AssessmentIcon />, children: [
        permissions['lead.report-mobile'] ? { url: '/leads-report', label: 'Leads Report', logo: null, exact: true } : false,
        permissions['showing.report-mobile'] ? { url: '/showing-report', label: 'Showing Report', logo: null, exact: true } : false,
        permissions['funnel-report.view'] ? { url: '/funnel-report', label: 'Funnel Report', logo: null, exact: true } : false,
        // permissions['cluster-lead.report'] ? { url: '/cluster-lead-report', label: 'Cluster Lead', logo: null, exact: true } : false,
        // permissions['cluster-showing.report'] ? { url: '/cluster-showing-report', label: 'Cluster Showing', logo: null, exact: true } : false,
        // permissions['pic-lead.report'] ? { url: '/pic-lead-report', label: 'PIC Lead', logo: null, exact: true } : false,
        permissions['ads-performance.view'] ? { url: '/dashboard-ads', label: 'Performance Ads', logo: null, exact: true } : false,
        permissions['report.lead-breakdown'] ? { url: '/enquiry-report', label: 'Leads Breakdown', logo: null, exact: true } : false,
      ]
    },
    {
      url: '#', type: 'parent', label: 'Master Data', logo: <StorageIcon />, children: [
        permissions['area.manage'] ? { url: '/area', label: 'Area', logo: null } : false,
        permissions['district.manage'] ? { url: '/district', label: 'District', logo: null } : false,
        permissions['kota-mandiri.manage'] ? { url: '/mandiri', label: 'Kota Mandiri', logo: null } : false,
        permissions['kawasan.manage'] ? { url: '/kawasan', label: 'Kawasan', logo: null } : false,
        permissions['cluster.list'] ? { url: '/cluster', label: 'Cluster', logo: null } : false,
        permissions['url-whatsapp.manage'] ? { url: '/url-placement', label: 'URL Placement', logo: null, exact: true } : false,
        permissions['listing.manage'] ? { url: '/listing', label: 'Unit', logo: null } : false,
        permissions['user.list'] ? { url: '/user-list', label: 'User', logo: null, exact: true } : false,
        permissions['user.agent-admin'] ? { url: '/agent-list', label: 'Agent', logo: null, exact: true } : false,
        permissions['agent-group.manage'] ? { url: '/agent-group', label: 'Agent Group', logo: null, exact: true } : false,
        permissions['lead-group.manage'] ? { url: '/lead-group', label: 'Cluster Lead Group', logo: null, exact: true } : false,
        permissions['telesales.manage'] ? { url: '/telesales-lead-group', label: 'Telesales Lead Group', logo: null, exact: true } : false,
        { url: '/contact', label: 'Contact', logo: null, exact: true },
        permissions['developer.manage'] ? { url: '/developer', label: 'Developer', logo: null, exact: true } : false,
        permissions['consent-form.manage'] ? { url: '/consent-form', label: 'Consent Form', logo: null, exact: true } : false,
      ]
    },
    {
      url: '#', type: 'parent', label: 'Escrow', logo: <AttachMoneyIcon />, children: [
        permissions['statement.manage'] ? { url: '/statement', label: 'Statement', logo: null, exact: true } : false,
        permissions['closing-transaction.list'] ? { url: '/verify-transaction', label: 'Verify Transaction Komisi', logo: null, exact: true } : false,
        permissions['closing-transaction.list'] ? { url: '/verify-transaction-loan', label: 'Verify Transaction Pinjaman', logo: null, exact: true } : false,
        permissions['closing-transaction.export'] ? { url: '/closing-transaction-export', label: 'Export List', logo: null, exact: true } : false,
        permissions['user-bank.manage'] ? { url: '/user-bank', label: 'User Bank', logo: null, exact: true } : false,
      ]
    },
    {
      url: '#', type: 'parent', label: 'Article', logo: <ArtTrackIcon />, children: [
        permissions['article.manage'] ? { url: '/article', label: 'Article Index', logo: null } : false,
        permissions['article.manage'] ? { url: '/article-navbar', label: 'Article Navbar', logo: null } : false,
        permissions['article.manage'] ? { url: '/article-kpr', label: 'Article KPR', logo: null } : false,
        permissions['article.manage'] ? { url: '/category', label: 'Category Index', logo: null } : false,
      ]
    },
    {
      url: '#', type: 'parent', label: 'Tools', logo: <SettingsIcon />, children: [
        permissions['log-login.view'] ? { url: '/login-logs', label: 'Login Logs', logo: null } : false,
        permissions['featured-clusters.manage'] ? { url: '/featured-clusters', label: 'Featured Clusters', logo: null } : false,
        permissions['site.setting'] ? { url: '/user-guide', label: 'User Guide', logo: null } : false,
        permissions['qontak.broadcast'] ? { url: '/system-broadcast', label: 'Qontak Broadcast', logo: null } : false,
        permissions['site.setting'] ? { url: '/release-app', label: 'Release App', logo: null } : false,
        permissions['blacklisted-phone.manage'] ? { url: '/blacklisted-phone', label: 'Blacklisted Phone', logo: null } : false,
        permissions['finance-tools.manage'] ? { url: '/finance-tools', label: 'Finance Tools', logo: null } : false,
        permissions['slp.manage'] ? { url: '/slp-icons', label: 'Icon SLP', logo: null } : false,
        permissions['whatsapp.check-by-phone'] ? { url: '/whatsapp-checker', label: 'Whatsapp Chat Checker', logo: null } : false,
      ]
    },
    permissions['asset.manage'] ? { url: '/asset-upload', label: 'Asset Upload', logo: <CloudUploadIcon />, exact: true } : false,
    permissions['site.setting'] ? { url: '/site-setting', label: 'Site Setting', logo: <SettingsIcon />, exact: true } : false,
    { url: '/profile-setting', label: 'Profile Setting', logo: <SettingsApplicationsIcon />, exact: true },
  ];

  const drawer = (
    <div>
      <div className={classes.menubarHeaderContainer}>
        {/* <Typography variant="h3" align="center">
          JD360
        </Typography> */}
        <img src={logo} className={classes.logo} alt="logo" />
      </div>
      {/* <Divider></Divider> */}
      <List>
        {generateLinks(url_links)}
      </List>
    </div>
  );

  const onLogout = () => {
    localStorage.clear();
    navigate('/login');
  }

  return (
    <Root>
      <div className={classes.root}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <CssBaseline />
        {
          !withoutAppBar &&
          <AppBar position="fixed" className={`${classes.appBar} ${desktopOpen ? classes.appBarShift : ''}`}>
            <Toolbar>
              {
                isMobile ?
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setMobileOpen(prev => !prev)}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  :
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setDesktopOpen(prev => !prev)}
                    className={classes.menuButton}
                  >
                    {
                      desktopOpen ?
                        <CropFreeIcon />
                        :
                        <MenuIcon />
                    }
                  </IconButton>
              }
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
              <IconButton
                color="inherit"
                edge="end"
                style={{ marginLeft: 'auto' }}
                onClick={() => navigate('/notification')}
              >
                <Badge variant="dot" color="error" overlap="circular" invisible={!isUnread}>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                edge="end"
                onClick={onLogout}
              // style={{ marginLeft: 'auto' }}
              >
                <ExitToAppIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        }
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {
            isMobile ?
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={() => setMobileOpen(prev => !prev)}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                  component: Root,
                }}
              >
                {drawer}
              </Drawer>
              :
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="persistent"
                open={desktopOpen}
                anchor="left"
              >
                {drawer}
              </Drawer>
          }
        </nav>
        <main
          className={`${classes.content} ${desktopOpen ? classes.contentShift : ''} ${isGrayBackground ? classes.grayBackground : ''}`}
          style={{
            padding: withoutMainPadding ? 0 : undefined
          }}
        >
          <Box component="div" sx={styles(theme).toolbar} />
          {children}
        </main>
      </div>
    </Root>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;